.Blog {
    width: 100%;
    margin: 60px auto;
    max-width: 1000px;
    padding: 40px 40px;
}

.Blog p {
    text-align: left;
    margin-bottom: 40px;
}

.Blog h1 {
    margin-bottom: 40px;
    font-size: 2.8rem;
}

.Blog h2 {
    margin-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
}

.Blog a {
    text-decoration: none;
    transition-property: color;
    transition-duration: .15s;
    color: rgba(3,2,1, 0.8);
    border-bottom: 1px dotted rgba(3,2,1, 0.8);
}


.Blog a:hover {
    color: #030201;
    border-bottom: 1px solid #030201;
}

@media(max-width: 499px) {
    .Blog {
        margin-top: 60px;
    }
}
