
.ProjectCardLeft, .ProjectCardRight {
    margin-bottom: 80px;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    -webkit-box-align: center;
    align-items: center;
}


.ProjectCardLeft .DescriptionContainer {
    grid-column: 1 / 8;
    transition: var(--transition);
    grid-area: 1 / 6 / -1 / -1;
    position: relative;
}

.ProjectCardRight .DescriptionContainer {
    grid-column: 7 / -1;
    text-align: right;
    position: relative;
    grid-area: 1 / 1 / -1 / 8;
    transition: var(--transition);
    position: relative;
}

.ProjectCardLeft .DescriptionContainer h3 {
    text-align: right;
    color: rgba(3,2,1, 0.9);
    margin-bottom: 10px;
    font-family: "Nunito Regular";
    text-transform: uppercase;
    font-size: 19px;
}

.ProjectCardRight .DescriptionContainer h3 {
    text-align: left;
    color: rgba(3,2,1, 0.9);
    margin-bottom: 10px;
    font-family: "Nunito Regular";
    text-transform: uppercase;
    font-size: 19px;
}


@media(min-width: 500px) {
    .TitleMobile {
        display:none;
    }
}

.Description {
    box-shadow: rgba(2, 12, 27, 0.7) 0 10px 30px -15px;
    z-index: 1;
    background-color: rgba(0,173,95, 0.9);
    color: #fff;
    padding: 25px;
    border-radius: 4px;
    font-size: 15px;
    text-align: right;
}


.ProjectCardRight .Description {
    text-align: left;
}

.ProjectCardLeft .ImageContainer{
    grid-column: 7 / -1;
    text-align: right;
    position: relative;
    grid-area: 1 / 1 / -1 / 8;
    border-radius: 4px;
    cursor: pointer;
    filter: none;
    transition: filter 800ms ease-out;
}

.ProjectCardRight .ImageContainer{
    text-align: right;
    position: relative;
    grid-column: 1 / 8;
    grid-area: 1 / 6 / -1 / -1;
    border-radius: 4px;
    cursor: pointer;
    filter: none;
    transition: filter 800ms ease-out;
}


.ImageContainer:hover {
    filter: grayscale(20%) contrast(1) brightness(90%);
}

img {
    width: 100%;
}

img:hover {
    opacity: 1;
}

.ListTech {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 12px 0px 0px;
    padding: 0px;
    list-style: none;
    justify-content: flex-end;
    font-family: "Nunito Regular";
}

.ProjectCardRight .ListTech {
    justify-content: flex-start;
    margin-left: -20px;
}

.ListTech li {
    margin: 0px 0px 5px 20px;
    font-size: 13px;
}

.iconContainer {
    display: inline;
    float: right;
}

.ProjectCardRight .iconContainer {
    float:left;
}

.Icon, .SecondIcon {
    width: 20px;
    display:inline-block;
    cursor: pointer;
}

.Icon {
    margin-right: 10px;
}

.Icon img:hover, .SecondIcon img:hover {
    background-color: transparent;
}

@media(max-width: 699px) {
    .ProjectCardContainer {
        /* max-width: 500px;
        margin: auto; */
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media(max-width: 499px) {
    .ProjectCardLeft, .ProjectCardRight {
        display:block;
    }

    .ProjectCardLeft .DescriptionContainer h3, .ProjectCardRight .DescriptionContainer h3 {
        /* margin: 10px 5px 10px 10px; */
        text-align: center;
        margin: 10px auto;
    }

    .ProjectCardLeft .iconContainer {
        margin-right: 15px;
    }

    .ProjectCardRight .iconContainer {
        margin-left: 15px;
    }

    .ProjectCardRight .DescriptionContainer h3, .ProjectCardLeft .DescriptionContainer h3 {
        display: none;
    }

    .TitleMobile {
        display: block;
        color: rgba(3,2,1, 0.9);
        margin-bottom: 10px;
        font-family: "Nunito Regular";
        text-transform: uppercase;
        font-size: 1.3rem;
    }

    .Description {
        text-align:center;
        font-size: 13px;
    }

    .ProjectCardLeft .ImageContainer, .ProjectCardsRight .ImageContainer {
        filter: none;
    }

    .ProjectCardRight .ListTech {
        margin-left: -5px;
    }
    .ProjectCardLeft .ListTech {
        margin-right: 15px;
    }

}