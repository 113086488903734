

.HomepageContent {
    width: 100%;
    margin: 80px auto 0px auto;
    max-width: 1000px;
    padding: 40px 40px;
}

div[code-wrapper] {
  margin-bottom: 40px; 
  width: 100%;
}

div[code] {
    white-space: pre;
    font-size: 13px;
    text-align: left;
    background-color: #272822;
    color: #fff;
    border-radius: 4px;
    line-height: 1.1rem;
    padding: 2rem;
}

div[code] div {
  overflow-x: auto;
}

div[fivepc] {
  margin-left: 5%; 
}

div[tenpc] {
  margin-left: 10%; 
}

div[fifteenpc] {
  margin-left: 15%; 
}

div[twentypc] {
  margin-left: 20%; 
}

span[var] {
  font-size: 14px;
  color: rgba(1, 1, 1, 0.7);
}


@media(min-width: 500px) {
  .HomepageContent {
    min-height: 800px;
  }
}

.HomepageContent p {
    text-align: left;
    margin-bottom: 40px;
}

@media(max-width: 499px) {
  .HomepageContent {
    margin-top: 40px;
    padding: 40px 0px;
  }

  .HomepageContent p {
    padding-left: 30px;
    padding-right: 30px;
  }

  div[code] {
    font-size: 11px;
  }
}


.HomepageContent h1 {
    margin-bottom: 40px;
    font-size: 2.8rem;
    line-height: 3.6rem;
}

.HomepageContent h2 {
    margin-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
}

.HomepageContent a {
    text-decoration: none;
    transition-property: color;
    transition-duration: .35s;
    color: rgba(3,2,1, 0.8);
    border-bottom: 1px dotted rgba(3,2,1, 0.8);
}

.HomepageContent a:hover {
    color: #030201;
    text-decoration: none;
    border-bottom: 1px solid #030201;
}


.HomepageContent img {
    width: 100% !important;
  }

