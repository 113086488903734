/* @font-face {
  font-family: "Roboto Mono Thin";
  src: url("./assets/fonts/RobotoMono-Thin.ttf");
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/fonts/RobotoMono-Light.ttf");
}

@font-face {
  font-family: "Roboto Mono Medium";
  src: url("./assets/fonts/RobotoMono-Medium.ttf");
}

@font-face {
  font-family: "Roboto Mono Regular";
  src: url("./assets/fonts/RobotoMono-Regular.ttf");
}

@font-face {
  font-family: "Roboto Mono SemiBold";
  src: url("./assets/fonts/RobotoMono-SemiBold.ttf");
}

@font-face {
  font-family: "Roboto Mono Bold";
  src: url("./assets/fonts/RobotoMono-Bold.ttf");
}

@font-face {
  font-family: "Nunito Extra Light";
  src: url("./assets/fonts/Nunito-ExtraLight.ttf");
}

@font-face {
  font-family: "Nunito Light";
  src: url("./assets/fonts/Nunito-Light.ttf");
}

@font-face {
  font-family: "Nunito Regular";
  src: url("./assets/fonts/Nunito-Regular.ttf");
}

@font-face {
  font-family: "Nunito Semi Bold";
  src: url("./assets/fonts/Nunito-SemiBold.ttf");
}

@font-face {
  font-family: "Lato Light";
  src: url("./assets/fonts/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato Regular";
  src: url("./assets/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato Bold";
  src: url("./assets/fonts/Lato-Bold.ttf");
} */



.App {
  text-align: center;
  font-family: "Roboto Mono Regular";
  font-size: 17px;
  line-height:28px;
  height: 100%;
  display: flex;
  flex-direction:column;
}

.App a {
  color: rgba(3, 2, 1, 0.8);
  transition: color ease-in-out 500ms, border ease-in-out 500ms;
  text-decoration: none;
  border-bottom: 1px dotted rgba(3, 2, 1, 0.4);
}

.App a:hover {
  color: rgba(3,2,1, 1);
  border-bottom: 1px solid rgba(3,2,1, 1);
}

.App a[project-link], .App a[project-link]:hover {
  color: #00ad5f;
  border-color: #00ad5f;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

h1 {
  font-family: "Nunito Extra Light";
  font-size: 2.8rem;
  line-height: 3.6rem;
  text-transform: uppercase;
}

h2 {
  font-family: "Nunito Light";
}
