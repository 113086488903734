.img-container img {
    width: 800px;
}

.SinglePost {
    margin-bottom: 200px;
}

.SinglePostDetail {
    width: 100%;
    margin: 60px auto;
    max-width: 1000px;
    padding: 40px 40px;
}

.SinglePostDetail p {
    text-align: left;
}

h1 {
    font-size: 2.8rem;
    font-weight: 500;
}

h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left: 20px;
    padding-right: 20px;
}

.HeaderImgContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index:0;
}

.SinglePostDetail {
    position: relative;
    margin-top: 700px;
    background-color: #fff;
    box-shadow: 0 -10px 30px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 -10px 30px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: 0 -10px 30px 0px rgba(0, 0, 0, 0.15);
    margin-bottom: 120px;
}

.SinglePostDetail div[code-wrapper] {
    margin-top: 40px; 
  }

  @media(min-width: 1080px) {
      .SinglePostDetail .img-container img {
          display: none;
      }
  }

@media(max-width: 1182px) {
    .SinglePostDetail {
        margin-top: 500px;
    }
}

@media(max-width: 1079px) {
    .HeaderImgContainer {
        display:none;
    }
    .SinglePostDetail {
        margin-top: 0px;
        width: auto;
    }

    .SinglePostDetail img {
        width: 100% !important;
    }
}

@media(max-width: 699px) {
    .SinglePostDetail {
        margin-top: 80px;
    }
}

@media(max-width: 499px) {
    .DesktopContainer {
        display: none
    }

    .MobileContainer h1, h1 {
        font-size: 2.3rem;
    }

    h2 {
        font-size: 1.8rem;
    }

    .SinglePostDetail {
        box-shadow: none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        padding: 40px 0px;
    }

    .SinglePostDetail p {
        padding-left: 30px;
        padding-right: 30px;
    }

    p, span {
        font-size: 13px;
    }

}

@media(min-width: 500px) {
    .MobileContainer {
        display: none;
    }
}

