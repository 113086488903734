.Projects {
    max-width:1000px;
    margin: 60px auto;
}

.ProjectCards {
    margin-top: 20px;
}

h1 {
    margin-top:40px;
    margin-bottom: 80px;
}

.AllProjectsTable {
    margin-bottom: 60px;
    margin-top: 120px;
}

.AllProjectsTable h2 {
    text-align: left;
    margin-top: 60px;
    margin-bottom: 20px;
}

.TableTitle {
    font-size: 1.4rem;
}

.ThingsCreated {
    margin-top: 80px;
}

.Projects a, .Projects a:hover {
    border-bottom: none;
}

@media(max-width: 499px) {
    .AllProjectsTable {
        margin: auto;
    }

    .AllProjectsTable h2 {
        margin-left: 10px;
        text-align: center;
    }

    .ThingsCreated {
        margin-top: 10px;
        margin-bottom: 60px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .Projects {
        padding-top: 80px;
    }

    .DesktopContainer {
        display: none
    }

    .MobileContainer h1 {
        font-size: 2.3rem
    }

    h2 {
        font-size: 1.8rem;
    }

    span, p {
        font-size: 13px;
    }

    .TableTitle {
        font-size: 1.2rem;
    }

}


@media(min-width: 500px) {
    .AllProjectsTable {
        margin-left: 15px;
        margin-right: 15px;
    }

    .MobileContainer {
        display: none;
    }
}
