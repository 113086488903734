
.Footer {
    background-color: #030201;
    color: #fff;
    padding-bottom: 40px;
    padding-top: 40px;
    margin-top: auto;
    width:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(max-width: 699px) {
    .Footer {
        min-height: 120px;
    }
}

@media(min-width: 450px) and (max-width: 499px){
    .CoffeeLink {
        margin-top: 10px;
    }
}

@media(max-width: 499px) {
    .MailLink {
        margin-top: 10px;
    }
}


.LinksContainer {
    display: flex;
    align-items: center;
    margin-left: -15px;
}

.DoneBy {
    margin-top: 10px;
    cursor: default;
    transition: color ease-in 300ms;
    font-family: "Nunito Light";
}

.DoneBy:hover {
    color: #00ad5f;
}

.Footer a {
    margin-bottom: -5px;
}

.Footer a, .Footer a:hover {
    border: none;
}

@media(max-width: 449px) {
    .LinksContainer a:last-child {
        margin-bottom:-15px;
    }

    .Footer {
        font-size: 13px;
    }
}
