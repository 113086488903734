.NavigationSingleItem {
    margin: 10px 0;
    box-sizing: border-box;
    display:block;
    height: 100%;
}

.NavigationSingleItem a {
    color: #fff;
    text-decoration: none;
    width: 100%;
    box-sizing: border-box;
    display:block;
}

.NavigationSingleItem a:hover,
.NavigationSingleItem a:active,
.NavigationSingleItem a.active {
    color: #00ad5f;
    text-decoration: none;
}

@media(min-width: 500px) {
    .NavigationSingleItem {
        margin: 0;
        display:flex;
        height: 100%;
        width: auto;
        align-items: center;
    }
    
    .NavigationSingleItem a {
        color: #fff;
        height: 100%;
        padding: 20px 14px;
        border-bottom: 4px solid transparent;
    }
    
    .NavigationSingleItem a:hover,
    .NavigationSingleItem a:active,
    .NavigationSingleItem a.active {
        background-color: #00ad5f;
        border-bottom: 1px solid transparent;
        color: #fff;
        -webkit-transition: all 400ms ease-out;
        -ms-transition: all 400ms ease-out;
        transition: all 400ms ease-out;
    }
}

@media(max-width: 499px) {
    .NavigationSingleItem {
        height: auto;
        margin-left: 6px;
        margin-right: 6px;
    }
}