
.PreviewPost {
    margin-bottom: 120px;
}

.PreviewPost .Preview {
    color: #d54062; 
    border-bottom: 1px dotted #d54062
}

a.SpecialLink {
    color: #00ad5f; 
    border-bottom: 1px dotted #00ad5f;
    display:inline;
    transition: all linear 6000ms;
}

a.SpecialLink:hover {
    color: #00ad5f; 
    opacity: 0.8;
    border-bottom: 1px solid #00ad5f;
}

.PreviewContainer {
    text-align: left;
}

.PreviewContainer p:last-of-type {
    display: inline;
}

@media(max-width: 699px) {
    .PreviewPost .PreviewContainer img {
        max-width: 100%;
    }
}


@media(max-width: 499px) {
    .DesktopContainer {
        display: none
    }

    .MobileContainer h1 {
        font-size: 2.6rem
    }

    .PreviewPost h1 {
        font-size: 2.3rem;
    }

    a.SpecialLink {
        font-size: 13px;
    }
}

@media(min-width: 500px) {
    .MobileContainer {
        display: none;
    }
}