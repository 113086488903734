
.HomepageHeader {
    padding-top: 60px;
    padding-bottom: 50px;
    margin: auto;
    font-family:"Roboto Mono Thin";
    font-size: 60px;
    line-height: 80px;
    letter-spacing: 16px;
    color: #fff;
    background-color: #030201;
}

@media(max-width: 699px) {
    .HomepageHeader {
        padding-top: 100px;
    }
}

.Separator {
    margin: auto;
    width: 300px;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #fff;
    font-size: 40px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, .45);
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 60px;
}

.Separator::after, .Separator::before {
    content: '';
    display: block;
    height: 0.09em;
    min-width: 30vw;
}

.Separator::before {
    background: linear-gradient(to right, rgba(240,240,240,0), #fff);
    margin-right: 4vh;
}

.Separator::after {
    background: linear-gradient(to left, rgba(240,240,240,0), #fff);
    margin-left: 4vh;
}


@media(max-width: 499px) {
    .Name {
        font-size: 30px;
        line-height: 52px;
    }
    .Separator {
        display: block;
        width: auto;
        font-size: 30px;
    }
}

