.Toolbar {
    height: 72px;
    width: 100%;
    background-color: #030201;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    box-sizing: border-box;
    z-index: 90;
    font-family: "Roboto Mono Thin";
    color: #fff;
}

@media(max-width: 699px) {
    .Toolbar {
        position: absolute;
    }
}

.Toolbar nav {
    height:100%;
    margin: auto;
}

.Logo {
    height: 80%;
}

@media(max-width: 499px) {
    .DesktopOnly {
        display: none;
    }
}