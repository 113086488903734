.TableHeader th {
    background-color: #030201 !important;
    border-color: #DEE2E6 !important;

}

.projectsTable table {
    font-family: "Nunito Light" !important;
}


tbody tr:hover {
    background-color: #030201;
    color: #fff;
    cursor: inherit;
}

th, td {
    cursor: default;
}

/***********************************/
.TableContainer {
    border-radius: 10px;
    /* box-shadow: 0 0 40px 0 rgba(0,0,0,.15);
    background-color: transparent; */
}
.TableHead, .TableHead table, .TableBody, .TableBody table {
    width: 100%
}

.TableHead table, .TableBody table {
    box-shadow: 0 0 40px 0 rgba(0,0,0,.15);
}

.Head th {
    padding-top: 18px;
    padding-bottom: 18px;
    padding-left: 40px;
    font-family: "Lato Light";
    font-size: 16px;
    color: #fff;
    line-height: 1.4;
    text-transform: uppercase;
    background-color: #00ad5f;
    width: 33%;
    letter-spacing: 0.5px;
}

.Head th:first-child {
    border-top-left-radius: 10px;
}

.Head th:last-child {
    border-top-right-radius: 10px;
}

.TableBody tbody tr:last-child td:first-child {
    border-bottom-left-radius: 10px;
}

.TableBody tbody tr:last-child td:last-child {
    border-bottom-right-radius: 10px;
}

.TableBody tbody tr {
    border-bottom: 1px solid rgba(0,173,95,.5);
    background-color: #111;
}

.TableBody tbody tr:hover td {
    /* background-color: rgba(34,34,34, 0.6); */
    opacity: 0.8;
}

.Body td {
    font-family: "Lato Regular";
    font-size: 16px;
    color: #fff;
    line-height: 1.4;
    background-color: #222;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 0px;
    width: 33%;
    background-color:#111;
}

.Body td a{
    color: #00ad5f;
    transition: opacity linear 400ms;
}

.Body td a {
    margin-right: 5px;
    margin-left: 5px;
    transition: all linear 500ms;
}

.Body td a:hover {
    opacity: 1;
    color: #fca311;
    text-decoration: none;
}

span.TechUsed {
    margin-left: 2px;
    margin-right: 2px;
    font-size: 14px;
}

span.Separator {
    margin-left: 4px;
    margin-right: 4px;
}


@media(max-width: 499px) {
    .tableForMobile {
        display: block;
    }

    .TableContainer {
        display:none;
    }

    svg {
        margin-bottom: -5px;
    }

	table, thead, tbody, th, td, tr { 
		display: block; 
	}
	
	thead tr { 
		position: absolute;
		top: -9999px;
        left: -9999px;
	}
	
    tr { 
        border: 1px solid #ccc;
        margin-bottom: 10px;
        background-color: #030201;
     }

    .Body td {
        width: 100%;
        background-color: rgba(34,34,34, 0.6);
    }
	
	td { 
		/* Behave  like a "row" */
		border: none;
		border-bottom: 1px solid #eee; 
		position: relative;
        padding-left: 50%; 
	}
	
	td:before { 
		/* Now like a table header */
		position: relative;
		width: 45%; 
		padding-right: 10px; 
        white-space: nowrap;
        color: #00ad5f;
	}
	
	/*
	Label the data
	*/
	td:nth-of-type(1):before { content: "Title"; }
	td:nth-of-type(2):before { content: "Built With"; }
    td:nth-of-type(3):before { content: "Link"; }
    
    h5 {
        font-size: 1.1rem;
    }

}

@media(min-width: 500px) {
    .tableForMobile {
        display:none;
    }
}

.CardContainer {
    width: 90%;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}
