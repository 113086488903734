.GitSvg, .ExternalSvg {
    fill: rgba(3,2,1, 0.8);
    cursor: pointer;
    transition: fill 400ms ease-in;
}

.GitSvgWhite, .ExternalSvgWhite {
    fill: rgba(255,255,255, 0.9);
    cursor: pointer;
    transition: fill 400ms ease-in;
}

.GitSvg, .GitSvgWhite {
    width: 22px;
}

.ExternalSvg, .ExternalSvgWhite  {
    width: 23px;
}

.GitSvg:hover, .GitSvgWhite:hover, .ExternalSvg:hover, .ExternalSvgWhite:hover {
    fill: #00ad5f;
}

.GitSvgFooter {
    fill: #fff;
    width: 20px;
    height: auto;
    margin-right: 10px;
    margin-left: 20px;
    cursor: pointer
}

.LinkedinSvg {
    fill: #fff;
    width: 20px;
    height: auto;
    margin-right: 5px;
    cursor: pointer;
}

.MailSvg {
    fill: #fff;
    width: 20px;
    height: auto;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -2px;
    cursor: pointer;
}

.BuyMeACoffeSvg {
    fill: #fff;
    width: 22px;
    height: auto;
    margin-left: 5px;
    margin-bottom: -2px;
    cursor: pointer;
}

.GitSvgFooter, .MailSvg, .LinkedinSvg, .BuyMeACoffeSvg:hover {
    transition: all ease-in 300ms;
}

.GitSvgFooter:hover, .MailSvg:hover , .LinkedinSvg:hover, .BuyMeACoffeSvg:hover {
    transform: translateY(-2px);
    fill: #00ad5f;
}

@media(max-width: 499px) {
    .GitSvg, .GitSvgWhite {
        width: 18px;
    }

    .ExternalSvg, .ExternalSvgWhite {
        width: 19px;
    }

    .GitSvgFooter, .MailSvg {
        width: 16px;
    }

    .LinkedinSvg{
        width: 18px;
        margin-bottom: -6px;
    }

     .BuyMeACoffeSvg {
        width: 18px;
    }
}