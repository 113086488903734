
.About {
    width: 100%;
    margin: 60px auto;
    max-width: 1000px;
    padding: 40px 40px;
}

.About p {
    text-align: left;
    margin-bottom: 40px;
}

.About h1 {
    margin-bottom: 80px;
    font-size: 2.8rem;
}

.About h2 {
    margin-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
}

.About a {
    text-decoration: none;
    transition-property: color;
    transition-duration: .15s;
    color: #2b6cb0;
    border-bottom: 1px dotted #2b6cb0;
}

.About a:hover {
    color: #fca311;
    text-decoration: none;
    border-bottom: 1px solid #fca311;
}

.PhotoContainer {
    margin-bottom: 50px;
}

.PhotoContainer img {
    filter: none;
    transition: filter 800ms ease-out;
}

.PhotoContainer img:hover {
    opacity: 0.9;
    background-color: #111;
    filter: grayscale(20%) contrast(1) brightness(90%);
}

.About img {
    width: auto;
}

@media(max-width: 499px) {
    .About {
        margin-top: 60px;
    }
    .DesktopContainer {
        display: none
    }

    .MobileContainer h1, h1 {
        font-size: 2.3rem
    }
}

@media(min-width: 500px) {
    .MobileContainer {
        display: none;
    }
}

.LightBox img {
    opacity: 1;
}