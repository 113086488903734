
@font-face {
  font-family: "Roboto Mono Thin";
  src: url("./assets/fonts/RobotoMono-Thin.ttf");
}

@font-face {
  font-family: "Roboto Mono";
  src: url("./assets/fonts/RobotoMono-Light.ttf");
}

@font-face {
  font-family: "Roboto Mono Medium";
  src: url("./assets/fonts/RobotoMono-Medium.ttf");
}

@font-face {
  font-family: "Roboto Mono Regular";
  src: url("./assets/fonts/RobotoMono-Regular.ttf");
}

@font-face {
  font-family: "Roboto Mono SemiBold";
  src: url("./assets/fonts/RobotoMono-SemiBold.ttf");
}

@font-face {
  font-family: "Roboto Mono Bold";
  src: url("./assets/fonts/RobotoMono-Bold.ttf");
}

@font-face {
  font-family: "Nunito Extra Light";
  src: url("./assets/fonts/Nunito-ExtraLight.ttf");
}

@font-face {
  font-family: "Nunito Light";
  src: url("./assets/fonts/Nunito-Light.ttf");
}

@font-face {
  font-family: "Nunito Regular";
  src: url("./assets/fonts/Nunito-Regular.ttf");
}

@font-face {
  font-family: "Nunito Semi Bold";
  src: url("./assets/fonts/Nunito-SemiBold.ttf");
}

@font-face {
  font-family: "Lato Light";
  src: url("./assets/fonts/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato Regular";
  src: url("./assets/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato Bold";
  src: url("./assets/fonts/Lato-Bold.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  overflow-x: hidden;
}
